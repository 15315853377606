body {
  color: $text-color;
  font: 14px $text-font-stack;
}

h1 {
  margin: 0;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}