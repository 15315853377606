.websites-table-col {
  margin-top: 40px;
}

#inline-edit {
  .success-checkmark {
    position: absolute;
    margin: auto;
    height: 40px;
    width: 40px;
    font-size: 90px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $success-color;
    display: none;
  }
}