@charset 'UTF-8';

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);

@import
  'abstracts/classes',
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

@import "node_modules/font-awesome/scss/font-awesome";

@import
  'vendor/bootstrap',
  'vendor/datatables',
  'vendor/normalize',
  'vendor/animate',
  'vendor/bootstrap-select',
  'vendor/bootstrap-datetimepicker',
  'vendor/datepicker3';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/icons',
  'base/helpers';

@import
  'layout/header',
  'layout/footer',
  'layout/navigation';

@import
  'components/breadcrumb',
  'components/button',
  'components/datalist',
  'components/forms',
  'components/status',
  'components/table',
  'components/links',
  'components/spaces';

@import
  'pages/all';