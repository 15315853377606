.clip {
  background-color: transparent;
  border: 0;

  @include on-event() {
    outline: none;
  }
}

.link-group {
  a.link {
    .fa {
      padding-left: 0;
    }
  }
}
a.link {
  color: $brand-color;
  text-decoration: none;

  .fa {
    padding-left: 6px;
    padding-right: 6px;
  }
}