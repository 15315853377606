.dataTables_filter {
  margin-bottom: 15px;
}

.dataTable {
  th {
    outline: none;

    @include on-event() {
      outline: none;
    }

    &.sorting, &.sorting_asc, &.sorting_desc {
      cursor: pointer;
    }
  }

  thead th.sorting:after {
    content: "\f0dc";
    color: $dark-grey;
    font-family: FontAwesome;
    font-size: .8em;
    padding-left: .5em;
    position: relative;
  }

  thead th.sorting_asc:after {
    content: "\f0de";
    color: $dark-grey;
    font-family: FontAwesome;
    padding-left: .5em;
    position: relative;
    top: .25em;
  }

  thead th.sorting_desc:after {
    content: "\f0dd";
    color: $dark-grey;
    font-family: FontAwesome;
    padding-left: .5em;
    position: relative;
    bottom: .125em;
  }
}