table.table {
  border-collapse: separate;
  border: solid $mid-grey 2px;
  border-radius: 3px;
  -moz-border-radius: 3px;

  td, th {
    border-left: solid $mid-grey 2px;
    border-top: solid $mid-grey 2px;

    &.center {
      text-align: center;
      margin: 0 auto;
    }
  }

  thead {
    tr {
      th {
        background-color: $light-grey;
        border-top: none;
        border-left: none;
        border-bottom: none;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: solid $mid-grey 2px;
        white-space: nowrap;
      }
    }
  }

  td:first-child, th:first-child {
    border-left: none;
  }

  td {
    border: none;
  }
}

table.table-space {
  th, td {
    padding: 10px 0 10px 0;
    min-width: 190px;
  }

  tr.original-row {
    td {

    }
  }
  tr.inline-edit-row {
    display: none;

    input {
      font-family: $text-font-stack;
      margin-top: -1px;
      margin-bottom: -1px;
      margin-left: -3px;
      min-width: 175px;
      min-height: 20px;
    }
  }
}

.dataTables_paginate {
  float: right;

  .current {
    font-weight: bolder;
  }
}

.dataTables_length {
  display: inline-block;

  label {
    font-weight: 400;
  }
}

.dataTables_filter {
  float: right;

  label {
    font-weight: 400;
  }
}

.paginate_button {
  padding-left: 10px;
  cursor: pointer;
}

.dataTables_info {
  display: inline-block;
}