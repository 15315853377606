$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

$text-color: rgb(34, 34, 34) !default;
$brand-color: rgb(34, 135, 227) !default;
$light-grey: rgb(253, 253, 253) !default;
$mid-grey: rgb(231, 231, 231) !default;
$dark-grey: rgb(215, 215, 215) !default;
$hard-grey: rgb(201, 201, 201) !default;
$success-color: rgb(42, 160, 75) !default;
$warning-color: rgb(253, 184, 36) !default;
$info-color: rgb(34, 135, 227) !default;
$danger-color: rgb(254, 87, 78) !default;

$max-width: 1180px !default;

$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

$base-url: '/assets/' !default;

$input-border-focus: rgb(255, 255, 255);