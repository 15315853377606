#login {
  align-items: center;
  background-color: $brand-color;
  display: flex;
  justify-content: center;

  &:before {
    background-image: url(/images/wave.svg);
    background-size: cover;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -99;
  }

  .login-logo {
    text-align: center;
    margin-bottom: 1em;

    img {
      max-width: 3em;
    }
  }

  .box {
    background-color: white;
    border-radius: 3px;
    padding: 4em 5em 5em 5em;

    @media(min-width: 768px) {
      width: 380px;
    }
  }

  form {
  }

  .remember {
    position: absolute;
    visibility: hidden;
  }

  .powered-by {
    color: $hard-grey;
    font-size: .875em;
    margin-bottom: 0;
    text-align: center;
  }
}