.form-control {
  border: 2px solid $mid-grey;
  color: $text-color;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  font-weight: normal;

  @include on-event() {
    border-color: $dark-grey;
  }

  &.disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
    opacity: 1;
  }

  .bootstrap-select {
    width: 100%;
  }
}

.form-group {
  &.has-error {
    .form-control {
      border: 2px solid $danger-color;
    }
  }
}

.required label:after {
  color: #e32;
  content: ' *';
  display: inline;
}

label.required:after {
  color: #e32;
  content: ' *';
  display: inline;
}

.help-block {
  color: $danger-color;
  font-size: 11px;
}

::-webkit-input-placeholder {
  color: $dark-grey;
}

:-moz-placeholder {
  color: $dark-grey;
  opacity: 1;
}

::-moz-placeholder {
  color: $dark-grey;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $dark-grey;
}

label.label-block {
  display: block;
}

label span {
  color: red;
}

.input-group-addon {
  border: 0;
}

.bootstrap-select {
  width: 100% !important;

  button {
    border: 2px solid #e7e7e7;
    color: #222222;
    border-radius: 3px;
    box-shadow: none !important;

    @include on-event {
      background-color: white !important;
      border-color: $dark-grey;
    }
  }
}