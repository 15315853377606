html {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  position: relative;
  width: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

.main-upper {
  margin-bottom: 30px;
}

.main-content {

}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.full-width {
  width: 100% !important;
}

hr {
  border-color: $mid-grey;
}