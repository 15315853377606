.space-10 {
  width: 100%;
  clear: both;
  height: 10px;
}

.space-20 {
  width: 100%;
  clear: both;
  height: 20px;
}

.space-30 {
  width: 100%;
  clear: both;
  height: 30px;
}

.space-40 {
  width: 100%;
  clear: both;
  height: 40px;
}

.space-50 {
  width: 100%;
  clear: both;
  height: 50px;
}

.space-60 {
  width: 100%;
  clear: both;
  height: 60px;
}

.space-70 {
  width: 100%;
  clear: both;
  height: 70px;
}

.space-80 {
  width: 100%;
  clear: both;
  height: 80px;
}

.space-90 {
  width: 100%;
  clear: both;
  height: 90px;
}

.space-100 {
  width: 100%;
  clear: both;
  height: 100px;
}