.margin-bottom {
  margin-bottom: 30px;
}

.margin-top {
  margin-top: 30px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}