.navbar {
  background-color: $brand-color;
  border-radius: 0;
  font-size: 12px;
  //margin-bottom: 30px;

  .navbar-brand {
    img {
      max-width: 1.25em;
      width: 100%;
    }
  }

  .navbar-nav {
    .open {
      a {
        background-color: inherit;
      }
    }

    li {
      text-transform: uppercase;

      &.active {
        a {
          font-weight: 700;
        }
      }

      a {
        color: white;

        @include on-event() {
          background-color: transparent !important;
        }
      }

      .dropdown-menu {
        box-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
        border: 0;
        border-radius: 4px;
        margin-top: -3px;

        &:before {
          background: #fff;
          box-shadow: -3px -3px 5px rgba(82, 95, 127, .04);
          border-radius: 4px 0 0 0;
          content: '';
          height: 10px;
          position: absolute;
          top: -5px;
          left: 50%;
          margin: 0 0 0 -5px;
          transform: translate(-50%, -50%);
          transform: rotate(45deg);
          width: 10px;
        }

        li {
          padding: 4px 0;

          a {
            color: black;
            font-size: 12px;
            text-transform: none;
          }
        }
      }
    }
  }
}