.btn {
  font-size: 1em;
  padding: .5em 1em;
  font-family: $text-font-stack;
  .fa {
    color: white !important;
    padding-left: 4px;
    padding-right: 4px;
  }

  &.btn-primary {
    background-color: $brand-color;
    border: 0;

    @include on-event() {
      background-color: rgb(9, 121, 221);
    }
  }

  &.btn-fw {
    width: 100%;
  }

  &.add-btn {
    margin-top: 20px;
  }

  &#inline-save {
    display: none;
    background-color: $success-color;
  }
}