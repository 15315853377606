.status {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-top: .5em;

  &.success, &.active {
    background-color: $success-color;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.danger, &.inactive {
    background-color: $danger-color;
  }
}